import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const ProductMaterialInfoWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const Image = styled.img`
  max-width: 15px;
  margin: 0px;
  margin-right: 5px;
    aspect-ratio: 1/1;
`

const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham";
  font-weight: bold;
  font-style: normal;

  @media (max-width: 850px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
`

export const ProductMaterialInfo = ({ intl, data }) => (
  <ProductMaterialInfoWrapper>
    <Image src="https://static.percko.com/uploads/39134e98-b919-44a1-9a3b-0abc14067b9d.svg" />
    <Text dangerouslySetInnerHTML={{ __html: data }} />
  </ProductMaterialInfoWrapper>
)

export default injectIntl(ProductMaterialInfo)
